.bottomSec {
    margin-top:10px;
    margin-bottom:10px;
   }
   .holdShrRghtNewFor {
     float:right;
   }
   .holdShrRghtNew > a > i{
    font-size: 18px;
    color: #66c1be;
   }
   .holdShrRghtNew img{
     height: 22px;
   }
   .floorPlan{
     background: #5ac4bf!important;
   }
   .floorPlanNew{
     background: #5ac4bf!important;
     margin-bottom:20px;
   }
   .reserve {
     background:  #FC0818!important;
   }
   .addparking{
    background:  #4073c4 !important;
   }
   .rejectBoxCntnt{
     overflow-y: scroll;
     width: 1040px;
     background: #fff;
     border: none;
     outline: none;
     border-radius: 12px;
     background-color: #fff;
     box-shadow: 0 24px 32px 0 rgba(0,8,8,.08);
     margin: 130px auto 0;
   
   }

   .rejectBoxCntnt1{
    overflow-y: scroll;
    width: 1040px;
    height:95vh;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 24px 32px 0 rgba(0,8,8,.08);
    margin: 10px auto 0;
    overflow-y: scroll;
  }
   .myForm{
       width:300px;
       margin-bottom:10px;
   }
   .rejectCancel{
       padding:10px;
   }
   .inputForm{
       border:0;
       width:100%;
   }
   .textArea{
     width:100%;
   }
   html,body{
     margin: 0 auto !important;
     padding: 0 !important;
     height: 100% !important;
     width: 100% !important;
     background: #fff;
     font-family: "Open Sans";
       font-family: 'Open Sans', sans-serif;
       font-weight: 400;
       font-size: 15px;
       line-height: 1.8;
       color: rgba(0,0,0,.4);
   }
   h1,h2,h3,h4,h5,h6{
     font-family: 'Open Sans', sans-serif;
     color: #000000;
     margin-top: 0;
 }
 .btn{
     padding: 10px 15px;
 }
 .btn.btn-primary{
     border-radius: 30px;
     background: #fff;
     color: #ffffff;
 }
 .pdf_body{
     margin: 0; padding: 0 !important; 
     /* mso-line-height-rule: exactly;  */
     color:#586772;
      background-color: #fff;
      width:80% !important;
      position: fixed;
      border-radius: 8px;
 }
 .pdf_center{
     width: 100%; 
     background-color: #fff;
 }
 .email-container{
     max-width: 1000px; margin: 0 auto; background-color: #fff;
 }
 .table_1{
     margin: auto;
 }
 .table_1_tr_td{
     padding-top:30px;
 }
 .verticalAlign{
     vertical-align: center;
 }
 .top_logo{
    max-width: 319px; height: auto;
 }
 .verticalAlignPad{
     padding-right:20px;vertical-align: center;
 }
 .pTop{
     margin:0; padding:0; font-size:18px; line-height: 24px;
 }
 .pTop2{
     margin:0; padding:0; font-size:30px; line-height: 38px;
 }
 .pTop3{
     margin:0; padding:0; font-size:15px; line-height: 20px; padding-top:5px; color:#000;
 }
 .paddingClass{
     padding-top:10px;
 }
 .pDetails{
     background-color: #697680; color: #fff; padding-left:12px;
 }
 .borderWithBack{
     background-color: #e6e8ea;border-bottom: 1px solid #6c6e71; padding-left:12px;
 }
 .borderWithBack2{
 background-color: #e6e8ea; border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;
 }
 .developmentClass{
     background-color: #e6e8ea; width: 120px; border-bottom: 1px solid #6c6e71; padding-left:12px;
 }
 .developmentShow{
     border-bottom: 1px solid #6c6e71; padding-left:12px;
 }
 .borderbotWithLeft{
     border-left: 1px solid #6c6e71;border-bottom: 1px solid #6c6e71; padding-left:12px;
 }
 .floorClass{
     background-color: #e6e8ea;  width: 220px;border-bottom: 1px solid #6c6e71; padding-left:12px;
 }
 .floorClass2{
     border-bottom: 1px solid #6c6e71; padding-left:12px; width: 280px;
 }
 .apartmentClass{
     background-color: #e6e8ea; border-left: 1px solid #6c6e71; width: 180px;border-bottom: 1px solid #6c6e71; padding-left:12px;
 }
 .borderLeftBack{
     background-color: #e6e8ea; border-left: 1px solid #6c6e71; padding-left:12px;
 }
 .borderBottomRight{
     border-bottom: 1px solid #6c6e71; border-right: 1px solid #6c6e71; height: 80px; border-left: 1px solid #6c6e71; padding-left:12px;
 }
 .padLeftLineHeight{
     padding-left:12px; line-height: 17px;
 }
 .width50Vert{
     width: 50%; vertical-align: top;
 }
 .paddRightClass{
     padding-right:32px;
 }
 .marPad{
     margin:0; padding:0;
 }
 .inputTypeClass{
     width: 100%; padding:8px 8px; box-sizing: border-box;
 }
 .padLeftClass{
     padding-left:25px;
 }
 .padLeftTop{
     padding-left:25px; padding-top:8px;
 }
 .vertWidth{
     /* vertical-align: bottom;  */
     width:25px;
 }
 .padTop12{
     padding-top: 12px;
 }
 .onlyWidth{
     width: 50%;
 }
 .inputTypeClass2{
     width: 100%; padding:8px 8px
 }
 .padLeft120{
     padding-left: 120px;
 }
 .padTop14{
     padding-top:14px;
 }
 .verWith{
     vertical-align: top; width:25px;
 }
 .linePad{
     line-height: 19px; padding-top:4px;
 }
 .heightClass{height: 8px;}
 .heightClass7{height: 7px;}
 .padLeft32{
     padding-left:32px;
 }
 .marPadTop{
     margin:0; padding:0; padding-top:8px;
 }
 .verticalAlignWidth{
     vertical-align: center; width:100px;
 }
 .marginPadd{
     margin:0; padding:0; padding-top:25px;
 }
 .marginPadd2{
     margin:0; padding:0; padding-top:12px;
 }
 .disPad{
     display: inline-block; padding-left:10px;
 }
 .disInline{
     display: inline-block;
 }
 .pad8{
     padding-top: 8px;
 }
 .pad3{
     padding-top: 3px;
 }
 .height25{
     height: 25px;
 }
 .height0{
     height: 0px;
 }
 .widthLineVert{
     width: 50%; vertical-align: top; line-height: 19px;
 }
 .marVertPad7{
     margin:0; padding:0; padding-top:7px;
 }
 .borderDotted{
     border-bottom: 1px dotted #000; height:35px; margin-bottom: 17px;
 }
 .padTop35{
     padding-top: 35px;
 }
 .inputTypeClass_input{border:0; width: 100%; box-sizing: border-box;}

 .modal-share{
    padding: 20px;
    width: 525px;
 }

 .modal-share input, .modal-share textarea{
     width: 100%;
 }
 .modal-share .row{
    margin-top: 10px;
 }

 /* The Close Button */

  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .submitPdfSuccessMsg{
    width: 70%;
    background: #008000c9;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    margin: auto 0px 20px;
  }
  .submitPdfButton{
    margin-bottom: 20px;
  }
  .modal-share .col-sm-12{
    margin-top: 25px;
  }
  .copyButton{
    width: 120px;
    float: right;
    text-align: center;
    margin-right: 15px;
    background: #cdcdcd;
    color: #fff;
    cursor: pointer;
  }
  .copyTextValue{
    width: 10px;
    height: 0;
    top: 0px;
    right: 0px;
    border: 0;
    position: absolute;
  }
  .shareTypeIcon{
    cursor: pointer;
  }
  .headingValueColor{
    color: rgba(55, 54, 54, 0.78);
  }
  .headingColor b{
    color: rgba(55, 54, 54, 0.78);
  }
  .checkBoxShow{
      margin:0 10px 10px 0;
  }
  .modal-classes-data{
      z-index:99999!important;
  }
  .heightClass20{
      height:20px;
  }
  .parkingtilte{
      font-size: 18px !important;
      color: #5ac4bf !important;
      font-weight: 600 !important;
      line-height: 60px;
  }

  .apartSec {
    display: flex;
	justify-content: space-between;
	align-items: center;
}
.apartMain {
    width: 100%;
	padding: 8px 8px;
    margin-bottom: 12px;
}
.apartSecLft label {
    color: #000000;
    margin-bottom: 0px;
}
.apartSecMid a {
    margin-right: 8px;
}
.apartSecMid a:last-child {
    margin-right: 0px;
}
.apartSecMid a span {
    margin-bottom: 0px;
    margin-right: 8px;
    font-size: 14px;
}
.apartSecMid a:last-child span {
    margin-right: 0px;
}
.apartSecMid a img {
    cursor: pointer;
    margin-right: 5px;
    vertical-align: middle;
}
.apartSecRght span img {
    cursor: pointer;
    vertical-align: middle;
    height: 22px;
    width: initial;
}
.apartSecRght span {
    margin-left: 4px;
}
a.btnPair {
    color: #ffffff !important;
    padding: 4px 9px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 3px;
    cursor: pointer;
}
.apartSecRght a {
    margin-right: 8px;
}
.apartSecRght span a {
    margin-left: 24px;
    margin-right: 0px;
}
a.holdBtn {
    background: #9eafc0;
}
a.reservBtn {
    background: #FC0818 !important;
}
.apartSecRght span a:last-child {
    margin-right: 0px;
}
a.availblBtn {
	background: #008080;
    color: #ffffff !important;
    padding: 4px 9px;
    font-weight: 300;
    font-size: 12px;
    border-radius: 0px;
    cursor: pointer;
    margin-left: 10px;
}
a.notAvailableBtn{
	background: #972127;
    color: #ffffff !important;
    padding: 4px 4px;
    font-weight: 300;
    font-size: 12px;
    border-radius: 0px;
    cursor: pointer;
    margin-left: 10px;
}
.apartDiscount a {
    margin-right: 15px;
}
.apartDiscount label {
    margin-bottom: 0px;
    font-weight: 600;
	margin-right: 8px;
	color: #5ac4bf;
}
.apartDiscount a:first-child label, .apartDiscount a:nth-child(2) label {
	color: #7f9292 !important;
}
.apartDiscount a:first-child span, .apartDiscount a:nth-child(2) span {
	color: #566262 !important;
}
.apartDiscount span {
    color: #000000;
    font-weight: 700;
}
.apartDiscount {
	padding-left: 37px;
    margin-top: 8px;
}
.apartUpr .apartMain:nth-child(even) {
	background-color: #d4f3f3  !important;
}
.apartUpr .MuiCheckbox-colorPrimary {
    padding: 0px !important;
    margin-right: 12px;
}
@media (max-width: 992px) {
	.apartSec {
		display: block;
		justify-content: initial;
	}
	.apartDiscount {
		padding-left: 0px !important;
		margin: 5px 0px;
	}
	.apartSecMid {
		margin: 6px 0px;
	}
}

.optioninShare input {
	max-width: 12px;
	margin-right: 8px;
}
.avlcheck{
    align-items: flex-start !important;
    padding: 5px !important;
}

/* ListViewcontent */
.LiStVIECONTAINER{
    padding: 0 5%;
}
.LiSTMIDCENT{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SpcaVailLeft{
    display: flex;
}
.BtnAVILSPC{
    width: 139px;
    font-weight: 900 !important;
}
.AdDMor{
    float:right !important;
}

.sBMT{
    margin-top: 30px;
}
.SELtSals{
    font-weight: 600;
    font-size: 14px;
    /* color: black; */
    margin: 35px 0 16px 0;
}
.availSPC{
    border: 1px solid lightgray !important;
}
.rld-single-select .single-select:hover, .rld-single-select .single-select:active, .rld-single-select .single-select:focus{
    border: 1px solid var(--main-color-one) !important;
}

.SpcaVailLeft select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
  }
  
  /* For IE10 */
  .SpcaVailLeft select::-ms-expand {
    display: none;
  }

  .SpcaVailLeft img{
    z-index: 999;
    position: absolute;

    margin-left: -24px;
 
    margin-top: 19px;
  }
  .SPCAvilPoup{
      margin: 2% 8% 5% 8%;
  }
  .toolTipItems{
      padding:10px !important;
  }
  .toolTipItems > h5{
    font-size: 15px !important;
    font-weight: 800 !important;
    margin-bottom: 8px !important;
    color: #faf3f3;
  }
  .toolTipItems > div{
    font-size: 11px !important;
    /* font-weight: 400 !important;
    margin-top: 6px !important; */
    line-height: 20px;
    margin-left: 4px !important;
    color: #faf3f3 !important;
}
  @media (max-width:550px){
    .BtnAVILSPC{
        width:unset !important;
     
    }
    .parkingtilte{
        line-height: 15px;
    }
    .sBMT{
        margin-top: 0;
    }
    .AdDMor{
        margin-top: 20px;
    }
  }
  .rld-single-select{
    width: -webkit-fill-available !important;
  }
  .parkingIfSelect {
    max-width: 119px;
    float: left !important;
    padding: 8px 8px;
    margin-right: 60px;
  }
  .parkingSpc{
    width: 360px;
    float: left;
    padding: 8px 8px;
    /* margin-right: 40px; */
  }
  .vertWidthParking{
    /* vertical-align: bottom;  */
    width:150px;
}
.vertWidthParkingSpc{
    /* vertical-align: bottom;  */
    width:100px;
}

.addparkingatunit{
    color: #ffffff !important;
    padding: 9px 12px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
    background:  #4073c4 !important;
}