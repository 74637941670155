.document-preview-modal .modal-body .document-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 15px;
}

.document-preview-modal .ReactPDF__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.document-container .ReactPDF__Error,
.document-container .ReactPDF__Loading,
.document-container .ReactPDF__NoData {
    background: white;
    padding: 3em;
}
