.pdf-controls {
    position: relative;
}
.pdf-controls-container {
    position: fixed;
    bottom: 15px;
    border-radius: 5px;
    padding: 0px 15px;
    left: 45%;
    display: flex;
    /* padding-bottom: 2%; */
    background-color: rgba(0,0,0,0.75);
}

.pdf-controls button {
    width: 1.75em;
    height: 1.75em;
    border: none;
    background-color: transparent;
    font-size: 25px;
    color: white;
}

.pdf-controls .zoom-to-fit {
    /* margin-bottom: 1em; */
}
.pageCount{
    margin-top: 10px;
    color: white;
    height: 24px;
    padding-right: 10px;
    border-right: 1px solid;
}

;